export const contact = () => {
    const fields = document.querySelectorAll('.contact__form-field')
    fields && fields.forEach(field => {
        const input = field.querySelector('input')
        const label = field.querySelector('label')

        input.addEventListener('focus', () => {
            label.classList.add('active');
            input.style.textAlign = 'left';
        });
        input.addEventListener('blur', () => {
            if (!input.value) {
                label.classList.remove('active')
                input.style.textAlign = 'right';
            }
        }
        )
    })

    const textareas = document.querySelectorAll('.textarea__optional')

    textareas.forEach(textarea => {
        textarea.addEventListener('focus', () => {
            textarea.style.textAlign = 'left'
        })
        textarea.addEventListener('blur', () => {
            if (!textarea.value) {
                textarea.style.textAlign = 'right';
            }
        })
    })
}

if (document.querySelector('#myMap')) {
    const x = document.querySelector('#myMap').getAttribute('data-x')
    const y = document.querySelector('#myMap').getAttribute('data-y')
    var map = L.map('myMap').setView([parseFloat(y), parseFloat(x)], 13);

    L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
        maxZoom: 19,
        attribution: '&copy; <a href="https://www.carto.com/attributions">CARTO</a>, &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);

    var customIcon = L.icon({
        iconUrl: document.querySelector('#myMap').getAttribute('data-icon'),
        iconSize: [38, 95], // Розмір іконки
        iconAnchor: [19, 60], // Точка якоря (де відображається маркер відносно координат)
    });

    var marker = L.marker([parseFloat(y), parseFloat(x)], { icon: customIcon }).addTo(map);
}

const selects = document.querySelectorAll('.custom-select');

    selects.forEach(select => {
        select.classList.add('hidden');
        const closest = select.closest('.wpcf7-form-control-wrap');
        const options = select.querySelectorAll('option');

        const div = document.createElement('div');
        div.classList.add('cs');

        const p = document.createElement('p');
        p.classList.add('cs_current');

        const ul = document.createElement('ul');
        ul.classList.add('another', 'hidden', 'cs_list');
        ul.style.display = 'none'

        options.forEach((option, index) => {
            const li = document.createElement('li');
            li.classList.add('another-f');
            li.innerHTML = option.textContent;

            if(index === 0){
                p.innerHTML = option.textContent;
                // li.classList.add('disabled');
            }

            if(!li.classList.contains('disabled')){
                li.addEventListener('click', () => {
                    select.selectedIndex = index;

                    p.innerHTML = li.textContent;
                    $(ul).slideUp(300);
                    ul.classList.remove('active');
                    p.classList.remove('active');

                    li.classList.add('active');
                    $(li).siblings('li').removeClass('active')
                })
            }

            ul.append(li);
        });

        if(closest){
            div.append(p);
            div.append(ul);
            closest.append(div);
        }

        p.addEventListener('click', () => {
            $(ul).slideToggle(300);
            ul.classList.toggle('active');
            p.classList.toggle('active');
        })
    })




