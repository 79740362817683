import { heroSlider } from "./modules/hero-slider.js";
import { studioSlider } from "./modules/studio-slider.js";
import { statementSlider } from "./modules/statement-slider.js";
import { faq } from "./modules/faq.js";
import { contact } from "./modules/contact.js";
import { filter } from "./modules/filter.js";
import { portfolio } from "./modules/portfolio.js";
import { social } from "./modules/social.js";
import 'fslightbox';

document.addEventListener("DOMContentLoaded", (event) => {

    //the scripts you need on the first screen.

    let scriptLaunchStart = true;
    //this function is called once, after a user interaction.
    const scriptLaunch = () => {
        /*the rest of the scripts, for example:
         * working with bibilio libraries, sliders, etc.
         */

        //== Start add burger-menu ==//
        heroSlider()
        studioSlider()
        statementSlider()
        faq()
        contact()
        filter()
        portfolio()
        social()


        const html = document.querySelector('html')
        const header = document.querySelector('.header')
        const burgerBtn = document.querySelector('.header__burger')
        const menuItems = document.querySelectorAll('.header__wp a')
        const burgerMenu = document.querySelector('.header__main')
        menuItems.forEach(item => {
            item.addEventListener('click', () => {
                burgerMenu.classList.remove('open')
                burgerBtn.classList.remove('open')
                header.classList.remove('open')
                changeHtmlOverflow('remove')
                burgerBtn.textContent = 'menu'
            })
        })

        burgerBtn.addEventListener('click', () => {
            burgerMenu.classList.toggle('open')
            burgerBtn.classList.toggle('open')
            header.classList.toggle('open')

            if (burgerMenu.classList.contains('open')) {
                burgerBtn.textContent = 'Close'
                changeHtmlOverflow('add')
            } else {
                burgerBtn.textContent = 'menu'
                changeHtmlOverflow('remove')
            }
        })

        const changeHtmlOverflow = (action) => {
            if (action === 'add') {
                html.style.overflow = 'hidden'
            }
            if (action === 'remove') {
                html.style.overflow = 'inherit'
            }
        }
        //== End add burger-menu ==//

        //==  Add hover ==//

        const getBtnAnimation = (btns) => {
            btns && btns.forEach(btn => {
                const title = btn.textContent
                const text = `<span class="label-up">${title}</span><span class="label-up">${title}</span>`
                btn.innerHTML = text
            })
        }

        const btns = document.querySelectorAll('.anim-btn')
        const menuBtns = document.querySelectorAll('.header__menu a')
        const footerMenuBtns = document.querySelectorAll('.footer__menu a')
        getBtnAnimation(menuBtns)
        getBtnAnimation(footerMenuBtns)
        getBtnAnimation(btns)

        //==  End hover ==//

        //==  hidden header ==//

        let prevScrollpos = window.pageYOffset;

        window.addEventListener('scroll', () => {
            const currentScrollPos = window.pageYOffset;
            if (prevScrollpos <= currentScrollPos && currentScrollPos > 50) {
                header.style.top = "-90px";
            } else {
                header.style.top = "0px";
            }

            prevScrollpos = currentScrollPos;
        })

        //==  hidden header ==//

    }
    if (window.pageYOffset >= 1 && scriptLaunchStart) {
        scriptLaunchStart = false;
        scriptLaunch();
    } else if (window.pageYOffset < 1 && scriptLaunchStart) {
        const events = ['mousemove', 'click', 'mousewheel', 'scroll', 'touchstart', 'keydown', 'keypress', 'mouseover', 'mouseout', 'resize'];
        events.forEach(event => {
            window.addEventListener(event, () => {
                if (scriptLaunchStart) {
                    scriptLaunchStart = false;
                    scriptLaunch();
                }
            });
        });
    }
});

