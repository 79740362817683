export const filter = () => {

    if (document.querySelector('.talents__filter-wrapper')) {
        const html = document.querySelector('html')
        const popupOpen = document.querySelector('.talents__filter-popup-button')
        const filterPopup = document.querySelector('.talents__filter-popup')
        const popupClose = document.querySelector('.talents__filter-close')

        popupOpen && popupOpen.addEventListener('click', () => {
            filterPopup.classList.toggle('open');
            if (filterPopup.classList.contains('open')) {
                changeHtmlOverflow('add')
            } else {
                changeHtmlOverflow('remove')
            }
        })
        popupClose && popupClose.addEventListener('click', () => {
            filterPopup.classList.remove('open');
            changeHtmlOverflow('remove')
        })


        const changeHtmlOverflow = (action) => {
            if (action === 'add') {
                html.style.overflow = 'hidden'
            }
            if (action === 'remove') {
                html.style.overflow = 'inherit'
            }
        }
    }

}