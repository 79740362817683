export const portfolio = () => {
    const portfolios = document.querySelectorAll('.portfolio__item');

    const handleVideoPlay = () => {
        portfolios.forEach(portfolio => {
            const image = portfolio.querySelector('.portfolio__item-pre');
            const video = portfolio.querySelector('.portfolio__item-video');

            if (image && video) {
                const playVideo = () => {
                    image.style.opacity = '0';
                    video.muted = true;
                    video.play();
                };

                const stopVideo = () => {
                    image.style.opacity = '1';
                    video.pause();
                };

                if (window.innerWidth <= 768) {
                    playVideo();
                } else {
                    portfolio.addEventListener('mouseover', playVideo);
                    portfolio.addEventListener('mouseout', stopVideo);
                }
            }
        });
    };

    handleVideoPlay();
    
    window.addEventListener('resize', () => {
    portfolios.forEach(portfolio => {
            const image = portfolio.querySelector('.portfolio__item-pre');
            const video = portfolio.querySelector('.portfolio__item-video');

            if (image && video) {
                const playVideo = () => {
                    image.style.opacity = '0';
                    video.muted = true;
                    video.play();
                };

                const stopVideo = () => {
                    if (window.innerWidth >= 768) {
                        image.style.opacity = '1';
                        video.pause();
                    }
                };

                portfolio.removeEventListener('mouseover', playVideo);
                portfolio.removeEventListener('mouseout', stopVideo);

                if (window.innerWidth <= 768) {
                    playVideo();
                } else {
                    stopVideo()
                    portfolio.addEventListener('mouseover', playVideo);
                    portfolio.addEventListener('mouseout', stopVideo);
                }
            }

        });
    });

}

// export const portfolio = () => {
//     const portfolios = document.querySelectorAll('.portfolio__item');

//     const playVideo = (portfolio) => {
//         const image = portfolio.querySelector('.portfolio__item-pre');
//         const video = portfolio.querySelector('.portfolio__item-video');
//         if (image && video) {
//             image.style.opacity = '0';
//             video.style.display = 'block';
//             video.muted = true;
//             video.play();
//         }
//     };

//     const stopVideo = (portfolio) => {
//         const image = portfolio.querySelector('.portfolio__item-pre');
//         const video = portfolio.querySelector('.portfolio__item-video');
//         if (image && video) {
//             image.style.opacity = '1';
//             video.pause();
//             video.style.display = 'none';
//         }
//     };

//     const setupEventListeners = () => {
//         portfolios.forEach(portfolio => {
//             const image = portfolio.querySelector('.portfolio__item-pre');
//             const video = portfolio.querySelector('.portfolio__item-video');

//             if (image && video) {
//                 if (window.innerWidth <= 768) {
//                     video.style.display = 'none';
//                     image.style.opacity = '1';
//                     portfolio.addEventListener('click', () => {
//                         if (video.paused) {
//                             playVideo(portfolio);
//                         } else {
//                             video.pause();
//                         }
//                     });

//                     // Remove mouseover and mouseout events for mobile
//                     portfolio.removeEventListener('mouseover', playVideo);
//                     portfolio.removeEventListener('mouseout', stopVideo);
//                 } else {
//                     video.style.display = 'none';
//                     image.style.opacity = '1';
//                     portfolio.addEventListener('mouseover', () => playVideo(portfolio));
//                     portfolio.addEventListener('mouseout', () => stopVideo(portfolio));

//                     // Remove click event for desktop
//                     portfolio.removeEventListener('click', playVideo);
//                 }
//             }
//         });
//     };

//     setupEventListeners();

//     window.addEventListener('resize', setupEventListeners);
// };




