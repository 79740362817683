import Swiper from "swiper";
import { Autoplay, Navigation, Pagination, Keyboard, EffectFade, /*Scrollbar,*/ EffectCoverflow, Controller } from "swiper/modules";

export const heroSlider = () => {
    const sliders = document.querySelectorAll('.hero__swiper')
    sliders.forEach(slider => {
        const swiper = new Swiper(slider, {
            modules: [Autoplay, EffectFade ],
            loop: true,
            slidesPerView: 'auto',
            // initialSlide: 2,
            speed: 5000,
            fadeEffect: {
                crossFade: true,
              },
            // allowTouchMove: false,
            watchSlidesVisibility: true,

            autoplay: {
                delay: 0,
                // disableOnInteraction: false,
            },
        });
    });
}
