export const faq = () => {
    if (document.querySelector('.faq__blocks')) {
        const icon = document.querySelectorAll('.faq__icon')
        const btns = document.querySelectorAll('.faq__button')
    
        btns.forEach(btn => {
          btn.addEventListener("click", () => {
            if (btn.classList.contains('active')) {
              btn.classList.remove('active');
              btn.nextElementSibling.style.maxHeight = null;
            } else {
              btns.forEach(item => {
                item.classList.remove('active');
                item.nextElementSibling.style.maxHeight = null;
              });
    
              btn.classList.add('active');
              const panel = btn.nextElementSibling;
              panel.style.maxHeight = panel.scrollHeight + "px";
            }
          });
        });
      }
}