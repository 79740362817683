import Swiper from "swiper";
import { Thumbs } from "swiper/modules";

export const studioSlider = () => {
    var side = new Swiper(".mySwiper", {
        loop: true,
        slidesPerView: 'auto',
        // centeredSlides: true,
        freeMode: true,
        watchSlidesProgress: true,
      });
      var main = new Swiper(".mySwiper2", {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 10,
        modules: [Thumbs],
        thumbs: {
          swiper: side,
        },
      });
}